import { Link as RouterLink } from 'react-router-dom';
import { Flex, Image, Box, Link, Text, IconButton, useDisclosure } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useColorMode } from '@chakra-ui/react';

function Header(props) {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box as="header" bg={colorMode === 'light' ? 'gray.900' : 'gray.800'} 
        color="white" 
        py="4"
        bgImage="url('/img/funkyback2.jpg')" 
        bgSize="cover" 
        bgRepeat="no-repeat"
        bgPos="bottom"
        >

      <Flex justifyContent={{ base: 'space-between', md: 'space-between' }} alignItems="center" maxW="1200px" mx="auto">
        <Link as={RouterLink} to="/">
          <Image src="/img/logo.gif" alt="Golden Egg logo" maxW="200px" />
        </Link>
        <Flex alignItems="center">
          <Box display={{ base: 'block', md: 'none' }} paddingRight="12px">
            <IconButton aria-label="Toggle navigation" icon={<HamburgerIcon />} colorScheme="facebook" onClick={() => { isOpen ? onClose() : onOpen()}} />
          </Box>
          <Box display={{ base: 'none', md: 'flex' }} alignItems="center">
            <Link as={RouterLink} to="/" mr="4" >
              <Text textStyle="headerLink">
                Home
              </Text>
            </Link>
            <Link as={RouterLink} to="/shows" mr="4" >
              <Text textStyle="headerLink">
                Shows
              </Text>
            </Link>
            <Link as={RouterLink} to="/media" mr="4" >
              <Text textStyle="headerLink">
                Media
              </Text>
            </Link>
            <Link as={RouterLink} to="/merch" mr="4">
              <Text textStyle="headerLink">
                Merch
              </Text>
            </Link>
          </Box>
        </Flex>
      </Flex>
      
      {/** Mobile menu below*/}

      <Box 
        position="absolute"
        zIndex="docked"
        display={{ base: isOpen ? 'block' : 'none', md: 'none' }} 
        py="5" 
        width="100%"
        px="55" 
        bgColor="rgba(5, 5, 5, 0.9)"
        transition="opacity 3s ease-in-out"
        opacity={isOpen ? 1 : 0}>

        <Link as={RouterLink} to="/" display="block" mb="4">
          <Text textStyle="headerLink"  onClick={onClose}>
            Home
          </Text>
        </Link>
        <Link as={RouterLink} to="/shows" display="block" mb="4" onClick={onClose}>
          <Text textStyle="headerLink">
            Shows
          </Text>
        </Link>
        <Link as={RouterLink} to="/media" display="block" mb="4" onClick={onClose}>
          <Text textStyle="headerLink">
            Media
          </Text>
        </Link>
        <Link as={RouterLink} to="/merch" display="block" mb="4" onClick={onClose}>
          <Text textStyle="headerLink">
            Merch
          </Text>
        </Link>
      </Box>
    </Box>
  );
}

export default Header;