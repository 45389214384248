import { Box, Container, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import ProductTile from './ProductTile'

function Merch() {
  const { colorMode } = useColorMode();
  
    // Dummy data for product tiles
    const products = [
      { name: 'The Secret - EP', image: '/img/merch/TheSecret-EP.png' },
      { name: 'Two Birds', image: '/img/merch/goldeneggbirdcd.jpg' },
      { name: 'Float', image: '/img/merch/float.jpg' },
      { name: 'Three Songs of Choice', image: '/img/merch/3songchoicecover.jpg' },
      { name: 'Cartoons of Ourselves Shirt', image: '/img/merch/eggshirt.jpg' },
    ];
  
  return (
    <Box bg={colorMode === 'light' ? 'gray.900' : 'gray.800'} pt="10" pb="20" color='white'>
 <Container maxW="1200px" px="4">
 <Heading size="lg" mb="4">Merch</Heading>
    <Text mb="4">
      Most of the items listed here are out of circulation. There may be limited availability at an upcoming show
    </Text>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
          {products.map((product) => (
            <GridItem key={product.name}>
              <ProductTile name={product.name} image={product.image} />
            </GridItem>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Merch;
