import { Box, Text } from '@chakra-ui/react';

function ProductTile(props) {
  const { name, image, description } = props;
  
  return (
    <Box pos="relative">
       <Box
        backgroundImage={`url(${image})`}
        backgroundPosition="center"
        backgroundSize="cover"
        height="0"
        paddingBottom="100%"
      />
      <Box
        position="absolute"
        bottom="0"
        right="0"
        p="1"
      >
        <Text textStyle="merchTitle" fontSize="3xl">{name}</Text>
      </Box>
    </Box>
  );
}

export default ProductTile;