import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  textStyles: {
    headerLink: {
      fontWeight: "bold",
      textShadow: "1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000",
      letterSpacing: "wide",
    },
    merchTitle: {
      fontWeight: "bold",
      textShadow: "2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000",
      letterSpacing: "wide",
    }
  },
  config: {
    initialColorMode: "dark",
  }
});

export default theme;