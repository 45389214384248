import { Box, Flex, Link } from '@chakra-ui/react';

function Footer() {
  return (
    <Box bg="gray.900" color="white" py="10">
      <Flex align="center" justify="space-between" maxW="1200px" mx="auto" px="4">
        <Box>
          <Link href="#">Terms of Use</Link> | <Link href="#">Privacy Policy</Link>
        </Box>
        <Box>
          &copy; {new Date().getFullYear()} Golden Egg
        </Box>
      </Flex>
    </Box>
  );
}

export default Footer;
