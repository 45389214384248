import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';

function ShowSchedule() {
  const { colorMode } = useColorMode();
  return (
    <Box bg={colorMode === 'light' ? 'gray.900' : 'gray.800'} pt="10" pb="20" color='white'>
      <Container maxW="1200px" px="4">
 
      <Heading size="lg" mb="4">Show Schedule</Heading>
      <Text mb="4" fontSize="xl" fontWeight="bold">
      Upcoming Shows
       </Text>
       <Text mb="4" fontSize="md">
      :-(  no shows currently scheduled
      check back for updates
      </Text>
        <hr/>
      <Text mb="4" fontSize="xl" fontWeight="bold">
      Past Show Archive
       </Text>
      </Container>
    </Box>
  );
}

export default ShowSchedule;


