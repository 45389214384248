import { Box, Container, Text, Heading } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';

function Content() {
  const { colorMode } = useColorMode();
  return (
    <Box bg={colorMode === 'light' ? 'gray.900' : 'gray.800'} pt="10" pb="20" color='white'>
      <Container maxW="1200px" px="4">
        <Heading size="lg" mb="4">
          Pictures, show schedules, all kinds of goodies for you folks out in radioland...
        </Heading>

        <Text mb="4" fontWeight="semibold">
          [The Secret EP - 17 year anniversary]
        </Text>

        <Text mb="4">
        Howdy everyone!
        </Text>
        <Text mb='4'>
        It's been some time since we've seen you all. We wanted to say hi. We hope you are all well, and keeping your chins up despite the many challenges that life can throw our way. We’d love to see all your smiling faces again at a live gig someday. We have still been jamming when we can. We even might have a nice group of new original tunes... We did notice that in this modern age though, you couldn’t access anything we had recorded. So we decided to re-release our last recording from 2006, "The Secret EP". It’s hard to believe that since this one originally came out, some of us have had children that are now driving cars. The real ones, not the imaginary kind!
        </Text>
        <Text mb='4'>
        Looking back, for all its flaws, we're proud of the songs, and that it was a complete DIY artistic expression from the band, with every part of the songwriting, recording/mixing and release all done ourselves. We did the initial tracking at the Superior Street Studios rehearsal spaces starting in 2004 in Chicago thanks to our friends  The Attics and then recorded over several months at various Chicagoland apartments and Mr. & Mrs. Horstman’s house in Springfield around 2005. Though CD’s were in decline, we did have a small run printed up with limited circulation. We seem to remember it being on MySpace as well? Anyway, as of today, April 20th, 2023, it’s now back on most all of the popular streaming services.
        </Text>
        <Text>
        Hope you enjoy the tunes, thanks for listening. 
        </Text><Text>
        Cheers,
        </Text><Text>
        Golden Egg
        </Text>
      </Container>
    </Box>
  );
}

export default Content;
