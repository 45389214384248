import { Box, Flex, Spacer } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Header from './components/Header';
import Content from './components/Content';
import Footer from './components/Footer';
import ShowSchedule from './components/ShowSchedule';
import Media from './components/Media';
import Merch from './components/Merch';


function App() {
  return (
    
    <Router>
      <Box bg="black" minH="100vh" maxWidth="100vw" overflowX="hidden">
        <Helmet>
          <title>Golden Egg - Midwest Rock from Springfield, IL</title>
          <meta name="description" content='Description' />
          <meta name="keywords" content='Golden Egg, band, mid-west, midwest, Springfield, Illinois, capitol, rock, imaginary car, P.A. Bill' />
        </Helmet>
        <Header />

        
        <Routes>
          <Route path="/shows" element={<ShowSchedule />} />
          <Route path="/media" element={<Media />} />
          <Route path="/merch" element={<Merch />} />
          <Route path="/" element={<Content />} />

        </Routes>
        
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
