import { Box, Container, Heading } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';
import YouTube from 'react-youtube';

function Media() {
  const { colorMode } = useColorMode();

  const opts = {
    height: '360',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <Box bg={colorMode === 'light' ? 'gray.900' : 'gray.800'} pt="10" pb="20" color='white'>
       
      <Container maxW="1200px" px="4">
      <Heading size="lg" mb="4">Media ( Music, Videos, Pics )</Heading>
        <iframe 
          title="Golden Egg on Spotify"
          src="https://open.spotify.com/embed/album/4q7yt7eSVBSx1pOeLGaSRV?utm_source=generator" 
          width="100%" 
          height="452" 
          frameBorder="0" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy" />

        <Box mt="8">
          <YouTube videoId="PMIdSLN1iK4" opts={opts} onReady={onReady} />
        </Box>
      </Container>
    </Box>
  );
}

export default Media;
